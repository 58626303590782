import { Col, Container, Row } from "react-bootstrap";

function HeroSection() {
  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col lg={12} xl={6}>
            <h1 className="display-2">The next evolution in industrial cameras</h1>
            <p className="lead">The only <mark><strong>wireless industrial camera</strong></mark> that reduces downtime and increases safety during fault finding in the production process.</p>
          </Col>
          <Col></Col>
        </Row>
      </Container>    </div>
  );
}

export default HeroSection;