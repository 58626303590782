import Section from "../components/section";
import { Card, Col, Row } from 'react-bootstrap';
import useCaseImage1 from '../images/useCase1.png';
import useCaseImage2 from '../images/useCase2.png';
import useCaseImage3 from '../images/useCase3.png';

function UseCaseSection() {

    const cardMinHeight = '520px';

    return (
        <Section id="usecases" title="Use Case Examples">
            <Row>
                <Col sm={12} md={4}>
                    <Card className="mb-2" style={{minHeight: cardMinHeight}}>
                        <Card.Img variant="top" src={useCaseImage1} alt="use case image"/>
                        <Card.Body>
                            <Card.Title>Troubleshooting Malfunctions</Card.Title>
                            <Card.Text>
                            Linekam can be strategically placed in hazardous or difficult-to-access areas of machinery to capture footage of intermittent issues. 
                            This allows engineers to analyze recorded video and identify root causes of problems without compromising safety or extending downtime periods, ultimately leading to faster and more efficient problem resolution. 
                            The wireless design enables flexible positioning, enhancing its troubleshooting capabilities.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4}>
                    <Card className="mb-2" style={{minHeight: cardMinHeight}}>
                        <Card.Img variant="top" src={useCaseImage2} alt="use case image"/>
                        <Card.Body>
                            <Card.Title>Employee Training</Card.Title>
                            <Card.Text>
                                The Linekam camera can be used to create comprehensive visual training materials by recording proper equipment operation and safety procedures.
                                These high-quality video resources can be utilized for onboarding new employees, conducting refresher courses for existing staff, and standardizing best practices across different shifts or locations, thereby enhancing overall workforce competency and safety.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4}>
                    <Card style={{minHeight: cardMinHeight}}>
                        <Card.Img variant="top" src={useCaseImage3} alt="use case image"/>
                        <Card.Body>
                            <Card.Title>Product Testing</Card.Title>
                            <Card.Text>
                                During the development of new products or processes, Linekam serves as a valuable tool for R&D teams. 
                                It can capture detailed footage of prototypes or experimental setups in action, allowing for thorough analysis of performance, identification of design flaws, and documentation of iterative improvements.
                                This visual data aids in refining products and accelerating the development cycle.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section>
    );
}

export default UseCaseSection;